<template>
  <component :is="layout">
    <div class="d-flex">
      <prompt
        :active="exportModal"
        :title="'Export Orders'"
        @close="exportModal = false"
      >
        <div slot="content">
          <export
            v-if="exportModal"
            :conditionData="conditionData"
            :viewId="view.id"
            :overview="'order'"
          />
        </div>
      </prompt>

      <OverviewSidebar
        class="p-4 justify-content-between fadeInUp"
        overview="order"
        @filter="onFilter"
        @filterTags="filterTags"
        @filterStores="filterStores"
        @filterStatus="filterStatus"
      >
        <FieldList
          v-if="isEdit"
          :fieldType="'order'"
          :selected-fields="selected"
          @add="addField"
          @deselect="removeField"
        />
      </OverviewSidebar>
      <div class="p-5 flex-fill fadeInUp has-sidebar" role="main">
        <HelpModal v-if="helpModal" @close="openHelp">
          <p>
            With views you're able to determine what columns with order data are shown in the filtered overview.
          </p>
          <p>
            Using filters you can view a subset of your orders.
          </p>
          <p>
            Sorting is available on most columns. Sort all orders filtered across pages.
          </p>
        </HelpModal>
        <ConditionBuilder
          v-if="renderConditionBuilder"
          @apply-filters="setConditionData"
          :conditionData="conditionData"
          :model="'order'"
        />
        <ApiLoader :is-loading="loading" class="px-0">
          <div class="card border-0 shadow-sm mb-4 w-100">
            <div class="card-header">
              <h5 class="h5">
                <span v-if="isEdit"><strong>Edit</strong> </span>
                {{ view.data.name }}:
                <span
                  v-if="orders.data && orders.data.length !== 0"
                  class="text-muted ml-4"
                  >{{ orders.total }} orders</span
                >
                <button
                  class="btn btn-primary page-intro"
                  @click="isEdit = !isEdit"
                >
                  <span v-if="isEdit">
                    Done
                  </span>
                  <span v-else>
                    Edit View
                  </span>
                </button>
                <button
                  class="btn btn-primary float-right mr-2"
                  @click="exportModal = true"
                  v-if="!isEdit"
                >
                  Export
                </button>
              </h5>
            </div>
            <div v-if="loading" class="card-body text-center mb-12 mt-12">
              <span class="spinner-border" role="status" aria-hidden="true" />
            </div>

            <div
              v-if="orders.data && orders.data.length !== 0"
              class="card-body"
            >
              <transition name="slide-fade">
                <FieldsReorder
                  :activeFields="selected"
                  v-if="isEdit"
                  @update="reorderFields"
                />
              </transition>
              <data-table
                :headers="headers()"
                :sort-url="'/order/view/' + view.id"
                :data="orders.data"
                @sort="setSorting"
              >
                <template
                  v-for="elem in selected"
                  :slot="elem.key"
                  slot-scope="item"
                >
                  <DataTableElement
                    :key="elem.key"
                    v-if="item.item._source[elem.key]"
                    :item-key="elem.key"
                    :item-value="item.item._source[elem.key]"
                    :currency="item.item._source.currency"
                  />
                </template>
                <template #actions="{ item }">
                  <router-link
                    :to="{ name: 'order.single', params: { id: item._id } }"
                    class="btn btn-sm btn-outline-secondary"
                  >
                    View order
                  </router-link>
                </template>
              </data-table>
              <div class="row">
                <div class="col-12 col-md-12">
                  <pagination
                    :data="orders"
                    :limit="3"
                    :show-disabled="true"
                    align="right"
                    @pagination-change-page="getOrders"
                  >
                    <span slot="prev-nav" class="text-capitalize"
                      ><i class="fas fa-chevron-left mr-2" /> Prev</span
                    >
                    <span slot="next-nav" class="text-capitalize"
                      >Next<i class="fas fa-chevron-right ml-2"
                    /></span>
                  </pagination>
                </div>
              </div>
            </div>
            <div
              v-if="!loading && orders.data && orders.data.length === 0"
              class="card-body"
            >
              <p class="text-muted">
                This order list is empty: You're seeing this message either
                because: 1. There are no relevant orders to show for the filter
                2. The plugin/api is not correctly connected 3. Automation.app
                is (unlikely) down. To resolve this, try new filtering options
                or contact
                <a href="mailto:nerds@automation.app">nerds@automation.app</a>.
              </p>
              <div class="p-4 rounded border-zip text-center">
                <p class="mb-0">
                  No recent orders has been found!
                </p>
              </div>
            </div>
          </div>
        </ApiLoader>
        <div class="page-help">
          <a @click="openHelp">
            <QuestionCircle />
          </a>
        </div>
      </div>
    </div>
  </component>
</template>

<script>
import Default from "@/layouts/default.vue";
import DataTable from "@/components/DataTable/DataTable.vue";
import DataTableElement from "@/components/DataTable/DataTableElement.vue";
import {
  loadFromApi,
  updateApi,
  buildPaginationString,
} from "@/helpers/apiConnection";
import { updateApiConfigurationData } from "@/helpers/apiConfigurationData";
import OverviewSidebar from "@/components/Order/OverviewSidebar.vue";
import ConditionBuilder from "@/components/Automation/ConditionBuilder.vue";
import ApiLoader from "@/components/UI/ApiLoader.vue";
import Prompt from "@/components/UI/Prompt.vue";
import Export from "@/components/Order/Export.vue";
import FieldList from "@/components/Order/FieldList.vue";
import FieldsReorder from "@/components/Order/FieldsReorder.vue";
import { getValues } from "@/helpers/utilities";
import QuestionCircle from "@/components/Svg/QuestionCircle.vue";
import HelpModal from "@/components/UI/HelpModal.vue";

export default {
  name: "OrderViewList",
  components: {
    ApiLoader,
    DataTable,
    DataTableElement,
    Default,
    OverviewSidebar,
    ConditionBuilder,
    Prompt,
    Export,
    FieldList,
    FieldsReorder,
    QuestionCircle,
    HelpModal,
  },
  data() {
    return {
      layout: "Default",
      viewId: "",
      orders: {
        data: [],
        links: {},
        total: 0,
      },
      view: {
        id: null,
        data: {
          name: "",
          view_type: "order",
          view: {},
        },
      },
      selected: {},
      fieldKeys: [],
      activeSegment: "",
      activeSearch: "",
      activeDateRange: "",
      activeTag: "",
      page: 1,
      loading: false,
      isEdit: false,
      conditionData: {},
      exportModal: false,
      renderConditionBuilder: true,
      helpModal: false,
    };
  },
  created() {
    if (this.view) {
      this.setupConfig();
    }
  },
  async mounted() {
    this.viewId = this.$route.params.id;
    if (this.$route.query.edit) {
      this.isEdit = this.$route.query.edit;
    }
    this.loading = true;
    this.view = await loadFromApi(`/configurationData/${this.viewId}`);
    await this.getOrders(1);
    this.setupConfig();
    this.loading = false;
  },
  methods: {
    setupConfig() {
      this.sortFields(this.view.data.view);
    },
    sortFields(viewData) {
      let fields = Object.values(Object.assign({}, viewData));
      fields = fields.sort((a, b) => (a.index > b.index ? 1 : -1));
      this.selected = {};
      fields.forEach((item) => {
        this.selected[item.key] = item;
      });
      this.fieldKeys = Object.keys(this.selected);
    },
    headers() {
      const header = [];
      this.fieldKeys.forEach((key) => {
        const item = this.selected[key];
        // if keys like billing_address.first_name, set sortable false
        if(item.key.includes(".")) {
          header.push({
            title: item.name,
            key: item.key,
            sortable: false,
            sortKey: item.key,
          });
        }else{
          header.push({
            title: item.name,
            key: item.key,
            sortable: true,
            sortKey: item.key,
          });
        }
      });
      header.push({
        title: "Actions",
        key: "actions",
      });
      return header;
    },
    setPage(page) {
      this.page = page;
      this.getOrders(page);
    },
    setSorting(sorting) {
      const sort = sorting.sortKey;
      const direction = sorting.direction;
      const filters = this.$route.query.filters;
      this.$router
        .replace({
          query: {
            sort: sort,
            dir: direction,
            filters: JSON.stringify(filters),
          },
        })
        .catch((error) => {
          console.log(error);
        });
      this.getOrders(this.page);
    },
    onFilter(condition) {
      this.conditionData = {
        conditions: condition.conditions,
        conditionGroup: condition.conditions_group,
        conditionName: condition.name,
      };
      const sortKey = this.$route.query.sort ?? "created_at";
      const sortDirection = this.$route.query.dir ?? "desc";
      this.$router
        .replace({
          query: {
            sort: sortKey,
            dir: sortDirection,
            filters: JSON.stringify(this.conditionData),
          },
        })
        .catch((error) => {
          console.log(error);
        });
      // if no conditions are set, reset the conditionbuilder component
      if (Array.isArray(condition)) {
        if (condition.length === 0) {
          this.renderConditionBuilder = false;
          this.$nextTick(() => {
            this.renderConditionBuilder = true;
          });
        }
      }
      this.getOrders(this.page);
    },
    filterStatus(status_key) {
      if(status_key) {
        const status_condition = {
          field: "status",
          field_type: "option",
          operator: "==",
          search: "status",
          type: "order",
          value: status_key
        };
        // incase we have add on to other conditions
        let conditions = this.conditionData.conditions;
        if(conditions) {
          conditions = conditions.filter(condition => condition.field !== "status");
          if(conditions.length === 0){
            this.conditionData = {
              conditionGroup: "AND",
              conditions: [status_condition]
            };
          }else {
            conditions.push(status_condition);
            this.conditionData.conditions = conditions;
          }
        } else {
          this.conditionData = {
            conditionGroup: "AND",
            conditions: [status_condition]
          };
        }
      } else {
        // reset condtions and clear the condition builder
        this.conditionData = {};
        this.renderConditionBuilder = false;
        this.$nextTick(() => {
          this.renderConditionBuilder = true;
        });
      }
      const sortKey = this.$route.query.sort ?? "created_at";
      const sortDirection = this.$route.query.dir ?? "desc";
      this.$router
        .replace({
          query: {
            sort: sortKey,
            dir: sortDirection,
            filters: JSON.stringify(this.conditionData),
          },
        })
        .catch((error) => {
          console.log(error);
        });
      this.getOrders(this.page);
    },
    filterTags(tag_slug) {
      if(tag_slug) {
        const tag_condition = {
          field: "tags",
          field_type: "tag",
          operator: "contains",
          search: "tags",
          type: "order",
          value: tag_slug
        };
        // incase we have add on to other conditions
        let conditions = this.conditionData.conditions;
        if(conditions) {
          conditions = conditions.filter(condition => condition.field_type !== "tag");
          if(conditions.length === 0){
            this.conditionData = {
              conditionGroup: "AND",
              conditions: [tag_condition]
            };
          }else {
            conditions.push(tag_condition);
            this.conditionData.conditions = conditions;
          }
        } else {
          this.conditionData = {
            conditionGroup: "AND",
            conditions: [tag_condition]
          };
        }
      } else {
        // reset condtions and clear the condition builder
        this.conditionData = {};
        this.renderConditionBuilder = false;
        this.$nextTick(() => {
          this.renderConditionBuilder = true;
        });
      }
      const sortKey = this.$route.query.sort ?? "created_at";
      const sortDirection = this.$route.query.dir ?? "desc";
      this.$router
        .replace({
          query: {
            sort: sortKey,
            dir: sortDirection,
            filters: JSON.stringify(this.conditionData),
          },
        })
        .catch((error) => {
          console.log(error);
        });
      this.getOrders(this.page);
    },
    filterStores(store_id) {
      if(store_id) {
        const store_conditon = {
          field: "store_id",
          field_type: "",
          operator: "==",
          search: "store_id",
          type: "order",
          value: store_id
        };
        let conditions = this.conditionData.conditions;
        if(conditions) {
          conditions = conditions.filter(condition => condition.field !== "store_id");
          if(conditions.length === 0){
            this.conditionData = {
              conditionGroup: "AND",
              conditions: [store_conditon]
            };
          }else {
            conditions.push(store_conditon);
            this.conditionData.conditions = conditions;
          }
        } else {
          this.conditionData = {
            conditionGroup: "AND",
            conditions: [store_conditon]
          };
        }
      } else {
        // reset conditions
        this.conditionData = {};
        this.renderConditionBuilder = false;
        this.$nextTick(() => {
          this.renderConditionBuilder = true;
        });
      }
      const sortKey = this.$route.query.sort ?? "created_at";
      const sortDirection = this.$route.query.dir ?? "desc";
      this.$router
        .replace({
          query: {
            sort: sortKey,
            dir: sortDirection,
            filters: JSON.stringify(this.conditionData),
          },
        })
        .catch((error) => {
          console.log(error);
        });
      this.getOrders(this.page);
    },
    getOrders: async function(page = 1) {
      const sortKey = this.$route.query.sort ?? "created_at";
      const sortDirection = this.$route.query.dir ?? "desc";
      const query = buildPaginationString(sortKey, sortDirection, page);
      const url = `/order/view/${this.viewId}?${query}`;
      const conditionData = this.$route.query.filters;
      this.orders = await updateApi(url, conditionData);
    },
    resetFilters() {
      this.getOrders(this.page);
    },
    getValues,
    setConditionData(conditionData) {
      this.conditionData = conditionData;
      const sortKey = this.$route.query.sort ?? "created_at";
      const sortDirection = this.$route.query.dir ?? "desc";
      this.$router
        .replace({
          query: {
            sort: sortKey,
            dir: sortDirection,
            filters: JSON.stringify(this.conditionData),
          },
        })
        .catch((error) => {
          console.log(error);
        });
      this.getOrders();
    },
    async updateView(view) {
      this.loading = true;
      const newView = await updateApiConfigurationData(this.viewId, view);
      if (newView) {
        this.view = newView;
        await this.setupConfig();
        await this.getOrders(1);
      }
      this.loading = false;
    },
    async addField(fieldKey, field) {
      const view = { ...this.view };
      field.index = view.data.view.length;
      view.data.view[fieldKey] = field;
      await this.updateView(view);
    },
    async removeField(fieldKey) {
      const view = { ...this.view };
      delete view.data.view[fieldKey];
      await this.updateView(view);
    },
    async reorderFields(fields) {
      this.sortFields(fields);
      const view = { ...this.view };
      view.data.view = fields;
      await this.updateView(view);
    },
    openHelp() {
      this.helpModal = !this.helpModal;
      window.scrollTo(0,0);
    },
  },
};
</script>

<style lang="scss" scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(10px);
  opacity: 0;
}
</style>
